<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader color="primary">
            <CRow>
              <CCol class=" text-white ">
                <h4>
                  <CIcon name="cil-search"/>
                  {{ onCheck(fields.title) }}
                </h4>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <CSelect :label="onCheck(fields.schoolName)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         :options="this.onCheck(campus)"/>
              </CCol>
              <CCol col="12">
                <CSelect :label="onCheck(fields.department)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectFacultys"
                         :options="onCheck(facultys)"/>
              </CCol>
              <CCol col="12">
                <CSelect :label="onCheck(fields.program)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         :options="onCheck(departments)"/>
              </CCol>

              <CCol col="12">
                <CInput type="text" :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                        :label="onCheck(fields.acadyear)"
                        v-model="data.acadyear"/>
              </CCol>
              <CCol col="12">

                <CInput type="text" :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                        :label="onCheck(fields.semeter)"
                        v-model="data.semeter"/>
              </CCol>

            </CRow>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton type="submit" color="primary">{{ this.onCheck(this.fields.title) }}</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <table-list
          :caption="this.onCheck(this.fields.instructor)"
          :items="data.items" :fields="this.onCheck(this.table.instructor)"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import TableList from "@/project/containers/sub/TableList";
import ConfigSettingCalendar from "@/project/containers/ConfigSettingCalendar";

export default {
  name: 'History',
  components: {ConfigSettingCalendar, TableList},
  data: function () {
    return {
      lang: "th",
      table: {
        instructor: [
          {
            key: "th",
            value: [
              {key: 'no', label: 'ลำดับ'},
              {key: 'code', label: 'รหัสวิชา'},
              {key: 'name', label: 'ชื่อรายวิชา'},
              {key: 'update', label: 'วันที่อัปเดท'},
              {key: 'status', label: 'สถานะ'},
              {key: 'action', label: '#', _style: 'text-align: center'},
            ]
          },
          {
            key: "en",
            value: [
              {key: 'no', label: 'no'},
              {key: 'code', label: 'code'},
              {key: 'name', label: 'code'},
              {key: 'update', label: 'update'},
              {key: 'status', label: 'status'},
              {key: 'action', label: '#', _style: 'text-align: center'},
            ]
          }
        ]
      },
      fields: {

        title: [
          {
            key: "th",
            value: "ค้นหา"
          },
          {
            key: "en",
            value: "ฆำฟพแ้"
          }
        ],
        instructor: [
          {
            key: "th",
            value: "อาจารย์ผู้รับผิดชอบรายวิชา และ อาจารย์ผู้สอน"
          },
          {
            key: "en",
            value: "Course Instructor"
          }
        ],

        schoolName: [
          {
            key: "th",
            value: "ชื่อสถาบันอุดมศึกษา"
          },
          {
            key: "en",
            value: "Name of University"
          }
        ],
        department: [
          {
            key: "th",
            value: "สำนักวิชา"
          },
          {
            key: "en",
            value: "School"
          }
        ],
        program: [
          {
            key: "th",
            value: "สาขาวิชา"
          },
          {
            key: "en",
            value: "Program"
          }
        ],
        acadyear: [
          {
            key: "th",
            value: "ปีการศึกษา"
          },
          {
            key: "en",
            value: "acadyear"
          }
        ],
        semeter: [
          {
            key: "th",
            value: "ภาคการศึกษา"
          },
          {
            key: "en",
            value: "semeter"
          }
        ],

      },
      data: {
        acadyear:'',
        semeter:'',
        items:[{
          no:1,
          code:"101111",
          name:"วัฒนธรรมศึกษา",
          update:"27/03/2020 18:00:00",
          status:"รอการตรวจสอบ"
        }],
      },
    }
  },
  mounted() {

  },

  created() {
    this.onInit();
  },


  beforeDestroy() {

  },

  methods: {
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    onInit() {

      this.$store.dispatch("course/onGetCampus")
      this.$store.dispatch("course/onGetFacultys")
      this.$store.dispatch("course/onGetCourses")
      this.$store.dispatch("course/onGetOperations")
      this.$store.dispatch("course/onGetVerifications")

    },

    onSelectFacultys(value, e) {
      this.$store.dispatch("course/onGetDepartment", value.code)
    },

  },

  computed: {
    ...mapGetters({
      campus: "course/objCampus",
      courses: "course/objCourses",
      facultys: "course/objFacultys",
      departments: "course/objDepartments",
    })
  },

  watch: {}
}
</script>
